import { Literal, Union } from "runtypes";

import { getNormalEnum } from "./runtypeEnums";

export const NumberDisplayFormatTypeLiteral = Union(
  Literal("PLAIN"),
  /** A localized number representation with separators and customizable decimal digits */
  Literal("NUMBER"),
  /** A localized representation of the number with 7 significant digits */
  Literal("NUMBER_7SIGFIG"),
  /** A localized representation of the number as a percent (e.g. 0.95 -> 95%) with customizable decimal digits */
  Literal("PERCENT"),
  /** A localized representation of the number as a percent (e.g. 0.95 -> 95%)
   * with two significant digits: 11%, 1.1%, 0.11% etc
   * appropriate for e.g. pie charts where the value ranges from very-small
   * to 100% and we don't want to round 0.11% to 0% like with PERCENT */
  Literal("PERCENT_2SIGFIG"),
  /** A localized representation of the number in scientific notation (e.g. 123 -> 1.23E2) with customizable decimal digits */
  Literal("SCIENTIFIC_NOTATION"),
  /** A localized representation of the number as an amount of `currency` */
  Literal("CURRENCY"),
  /**
   * Like CURRENCY, but with an expanded currency symbol.
   * Useful for disambiguating currencies with the same symbol.
   * For example, Australian dollars is "A$" instead of just "$" in this mode.
   */
  Literal("CURRENCY_LONG"),
  /** A localized representation of the number as an amount of `currency`, typically with parens instead of a negative sign */
  Literal("FINANCIAL"),
);

export const NumberDisplayFormatType = getNormalEnum(
  NumberDisplayFormatTypeLiteral,
);
