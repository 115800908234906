import { HexColor, IconType } from "./enums.js";
import { OrgId } from "./idTypeBrands";

export const GLOBAL_ORG_ID = "global" as OrgId;
export const GLOBAL_ORG_NAME = "Global";

export const ORG_DISPLAY_NAME_REGEX = "^[A-Za-z0-9'_ -]+$";

const POST_ORG_PICKER_REDIRECT_ROUTES = ["new-project"];

/**
/**
 * TODO(VELO-4968):revisit this if we broaden redirect
 * support.
 * Checks to see if redirectTo is a valid post org picker redirect route -> we
 * only want to redirect to this route after landing a user in an specific org.
 * Workspace picker will redirect to /:orgId/:redirectTo
 */
export const isKnownPostOrgPickerRedirect = (redirectTo: string): boolean => {
  const baseRoute = redirectTo?.split("?")[0]?.split("/")[1];

  if (!baseRoute || !POST_ORG_PICKER_REDIRECT_ROUTES.includes(baseRoute)) {
    return false;
  }
  return true;
};

// Org IDs that we may need for other parts of our URL
export const RESERVED_ORG_IDS = [
  "new-project",
  "storage",
  "auth",
  "auth-all",
  "home",
  "app",
  "admin",
  "account",
  "admin-platform",
  "terms",
  "terms-and-conditions",
  "privacy-policy",
  "project",
  "profile",
  "billing",
  "compute",
  "security",
  "static",
  "build",
  "upgrade",
  "org",
  "orgs",
  "search",
  "new",
  "api",
  "internal-api",
  "v0",
  "v1",
  "v2",
  "v3",
  "v4",
  "v5",
  "v6",
  "settings",
  "invite",
  "member",
  "members",
  "user",
  "users",
  "team",
  "teams",
  "redirect",
  "usage",
  "stats",
  "insights",
  "data",
  "graphql",
  "subscriptions",
  "dist",
  "export",
  "org-redirect",
  "org-selector",
  "healthcheck",
  "public",
  "queues",
  "login",
  "login-success",
  "log-in",
  "logout",
  "log-out",
  "logout-all",
  "log-out-all",
  "signin",
  "sign-in",
  "signup",
  "sign-up",
  "signout",
  "sign-out",
  "spellbook",
  "hooks",
  "notion-unfurl",
  "create-customer-portal-session",
  "create-checkout-session",
  "bigquery-oauth-success",
  "snowflake-oauth-success",
  "databricks-oauth-success",
  "welcome",
  // todo(ENG-3717) - move these four GH authe routes to use nested /github/... routes
  "github-connect-success",
  "github-install-landing",
  "githubsync-connect-success",
  "githubsync-install-landing",
  "gitlabsync-install-landing",
  "slack-auth",
  "app-icon",
  "favicon",
  "zd-jwt",
  "collection",
  "collections",
  "component",
  "components",
  "quick",
  "new-quick",
  "workspace-picker",
  "link",
  "new-trial",
  "google-drive-oauth-complete",
];

export const DEFAULT_CATEGORIES = [
  {
    color: HexColor.RED,
    name: "Deep dive",
    description:
      "A one-off analysis, intended to be read linearly as a written up document, with results that reflect a point in time",
  },
  {
    color: HexColor.ORANGE,
    name: "Dashboard",
    description: "Dashboard that shows recent results",
  },
  {
    color: HexColor.BLUE,
    name: "Feature engineering",
    description: "Project for prototyping, training, and testing ML models",
  },
  {
    color: HexColor.GRAY,
    name: "Scratchpad",
    description:
      "Personal scratchpad for a team member. Not intended for broad consumption",
  },
  {
    color: HexColor.PURPLE,
    name: "Template",
    description:
      "Template to be duplicated and used as a starting point for additional analyses",
  },
  {
    color: HexColor.TEAL,
    name: "Product",
    description: null,
  },
  {
    color: HexColor.GREEN,
    name: "Sales",
    description: null,
  },
  {
    color: HexColor.TEAL,
    name: "Finance",
    description: null,
  },
  {
    color: HexColor.RED,
    name: "Ops",
    description: null,
  },
  {
    color: HexColor.PURPLE,
    name: "Marketing",
    description: null,
  },
  {
    color: HexColor.YELLOW,
    name: "External",
    description: null,
  },
];

export const DEFAULT_STATUSES = [
  {
    color: HexColor.YELLOW,
    name: "In development",
    description: "Currently in progress, not yet ready for use",
    endorsed: false,
    enforcesReview: false,
    icon: null,
  },
  {
    color: HexColor.BLUE,
    name: "Completed",
    description: "Completed and ready for use",
    endorsed: false,
    enforcesReview: false,
    icon: null,
  },
  {
    color: HexColor.GREEN,
    name: "Certified",
    description: "Completed and has been vetted by the data team",
    endorsed: true,
    enforcesReview: true,
    icon: IconType.chexagon,
  },
];
