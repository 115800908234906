import { OrgRole } from "./enums";

export const ORG_ROLE_ORDERING = [
  OrgRole.ADMIN,
  OrgRole.MANAGER,
  OrgRole.EDITOR,
  OrgRole.EXPLORER,
  OrgRole.MEMBER,
  OrgRole.GUEST,
  OrgRole.ANONYMOUS,
];

export const humanReadableOrgRole = (orgRole: OrgRole): string =>
  ({
    [OrgRole.ADMIN]: "Admin",
    [OrgRole.MANAGER]: "Manager",
    [OrgRole.EDITOR]: "Editor",
    [OrgRole.EXPLORER]: "Explorer",
    [OrgRole.MEMBER]: "Viewer",
    [OrgRole.GUEST]: "Guest",
    [OrgRole.ANONYMOUS]: "Anonymous",
  })[orgRole];

export const orgRoleDescription = (orgRole: OrgRole): string =>
  ({
    [OrgRole.ADMIN]:
      "Can access all projects in the workspace, create projects, & manage workspace assets, users & settings",
    [OrgRole.MANAGER]:
      "Can access all projects shared with the workspace & create projects. Can also curate some workspace assets.",
    [OrgRole.EDITOR]:
      "Can access all projects shared with the workspace & create projects.",
    [OrgRole.EXPLORER]:
      "Can access all projects shared with the workspace & create Explores. Cannot create, edit, or duplicate projects.",
    [OrgRole.MEMBER]:
      "Can access all projects shared with the workspace. Cannot create, edit, or duplicate projects.",
    [OrgRole.GUEST]: "Can only access projects individually shared with them.",
    [OrgRole.ANONYMOUS]:
      "Not logged in as Hex users. Can only view projects explicitly shared with the web.",
  })[orgRole];

export const orgRoleHasEditPermissions = (
  orgRole: OrgRole | undefined,
): boolean => {
  return orgRole != null
    ? {
        [OrgRole.ADMIN]: true,
        [OrgRole.MANAGER]: true,
        [OrgRole.EDITOR]: true,
        [OrgRole.EXPLORER]: false,
        [OrgRole.MEMBER]: false,
        [OrgRole.GUEST]: true,
        [OrgRole.ANONYMOUS]: false,
      }[orgRole]
    : false;
};

export const isOrgRoleSuperset = (
  orgRole: OrgRole,
  targetOrgRole: OrgRole,
): boolean => {
  if (ORG_ROLE_ORDERING.indexOf(orgRole) === -1) {
    console.error(`Invalid role ${orgRole}`);
    return false;
  }

  return (
    ORG_ROLE_ORDERING.indexOf(orgRole) <=
    ORG_ROLE_ORDERING.indexOf(targetOrgRole)
  );
};

export const maxOrgRole = (roles: readonly OrgRole[]): OrgRole | undefined => {
  return [...roles].sort(
    (a, b) => ORG_ROLE_ORDERING.indexOf(a) - ORG_ROLE_ORDERING.indexOf(b),
  )[0];
};

export const isPaidRole = (orgRole: OrgRole): boolean => {
  return {
    [OrgRole.ADMIN]: true,
    [OrgRole.MANAGER]: true,
    [OrgRole.EDITOR]: true,
    // TODO(explorer-role): Will be considered paid
    [OrgRole.EXPLORER]: false,
    [OrgRole.MEMBER]: false,
    [OrgRole.GUEST]: false,
    [OrgRole.ANONYMOUS]: false,
  }[orgRole];
};
